import { Button, makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import { ShowFnOutput } from "mui-modal-provider/dist/types";
import Image from "next/image";
import { createElement, MouseEvent, useMemo, useState, VFC } from "react";
import { useQuestsState } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { YoutubeVideoPlayerModal } from "../YoutubeVideoPlayerModal";
import { QuestConfig, QuestGroup, QuestStepProps } from "./quests.types";
import { getQuestConfigFromActiveQuest } from "./quests.util";
import { QuestVideoPlayerModalProps } from "./QuestVideoPlayerModal";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 340,
      display: "flex",
      flexDirection: "column",
    },
    textAndBtns: {},
    description: {
      padding: theme.spacing(0.5, 2, 2, 2),
    },
    btnCont: {
      margin: theme.spacing(0, 0, 0.5, 1),
    },
    btn: {
      color: theme.palette.common.black,
    },
    imgWrapper: {
      width: "100%",
      "& > span": {
        position: "unset !important",
      },
      "& > span > img": {
        objectFit: "contain",
        width: "100% !important",
        position: "relative !important",
        height: "unset !important",
      },
    },
    notReady: {
      "& > span > img": {
        height: "0 !important",
      },
    },
    svg: {},
    skeleton: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    noDisplay: {
      display: "none",
    },
    closeBtn: {
      minWidth: 0,
    },
    progressAndClose: {
      margin: theme.spacing(1, 1, 0, 2),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    progressTxt: {
      opacity: 0.5,
    },
  }),
  {
    classNamePrefix: "QuestGenericPopover",
  }
);

export type QuestGenericPopoverJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestGenericPopoverProps = QuestStepProps & {
  classes?: Partial<ClassNameMap<QuestGenericPopoverJSSClassKey>>;
  className?: string;
};

export const QuestGenericPopover: VFC<QuestGenericPopoverProps> = ({
  className,
  classes: extClasses,
  stepConfig,
  onClose,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const { showModal } = useModal();

  const { activeQuest } = useQuestsState();

  const [imageReady, setImageReady] = useState(false);

  const config = useMemo<QuestConfig<QuestGroup> | undefined>(
    () => (activeQuest ? getQuestConfigFromActiveQuest(activeQuest) : undefined),
    [activeQuest]
  );

  const activeStepIndex: number | undefined = useMemo(
    () => config?.steps.findIndex((s) => s.id === activeQuest?.step),
    [config, activeQuest]
  );

  const totalSteps: number | undefined = useMemo(() => {
    return config?.steps.length || undefined;
  }, [config]);

  const handleClose = useCallbackSafeRef(() => {
    onClose?.();
  });

  const preventDefault = useCallbackSafeRef((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  });

  const handleVideoClose = useCallbackSafeRef((modal: ShowFnOutput<QuestVideoPlayerModalProps>) => {
    modal.hide();
  });

  const handleWatchVideo = useCallbackSafeRef(() => {
    const modal = showModal(YoutubeVideoPlayerModal, {
      onClose: () => handleVideoClose(modal),
      videoLink: stepConfig.videoLink,
      videoOptions: stepConfig.videoOptions,
    });
  });

  return (
    <div className={clsx(classes.root, className)} onMouseOver={preventDefault} onClick={preventDefault}>
      {(() => {
        if (!!stepConfig.popoverConfig?.img) {
          return (
            <>
              <Skeleton
                className={clsx(classes.skeleton, { [classes.noDisplay]: imageReady })}
                variant="rect"
                height={200}
                width="100%"
              />
              <div className={clsx(classes.imgWrapper, { [classes.notReady]: !imageReady })}>
                <Image layout="fill" src={stepConfig.popoverConfig.img} onLoadingComplete={() => setImageReady(true)} />
              </div>
            </>
          );
        } else if (!!stepConfig.popoverConfig?.svg) {
          return createElement(stepConfig.popoverConfig.svg, { className: classes.svg });
        }
      })()}
      <div className={classes.textAndBtns}>
        <div className={classes.progressAndClose}>
          {!!activeStepIndex && !!totalSteps ? (
            <Typography variant="body1" className={classes.progressTxt}>
              {activeStepIndex} of {totalSteps - 1}
            </Typography>
          ) : (
            <Skeleton variant="rect" width={40} height={16}></Skeleton>
          )}
          <Button onClick={handleClose} className={classes.closeBtn} color="default" variant="text">
            <CloseRoundedIcon fontSize="small" />
          </Button>
        </div>
        <Typography variant="body1" className={classes.description}>
          {stepConfig.popoverConfig?.description}
        </Typography>
        <div className={classes.btnCont}>
          {stepConfig.type === "orb" && (
            <Button className={classes.btn} onClick={handleClose}>
              Next
            </Button>
          )}
          {!!stepConfig.videoLink && (
            <Button className={classes.btn} onClick={handleWatchVideo}>
              Watch Video
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
